import { PageProps } from '@lp-root/src/utils/local-types';
import { Grid } from '@web/atomic/legacy/obj.grid/grid.component';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import LoadableMultistepQuiz from '../modules/quiz-b2b/multistep-quiz.loadable';
import { CustomQuery } from './quiz-b2b';

const LandingPagePsicologiaViva: React.FunctionComponent<PageProps<unknown, CustomQuery>> = () => {
  return (
    <CleanLayout>
      <Grid>
        <LoadableMultistepQuiz resultsPath={'/resultado-quizz-pv-657098d6519bbb41b1d8415d949385693f63458e'} />
      </Grid>
    </CleanLayout>
  );
};

export const query = graphql`
  query LandingPagePsicologiaVivaQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default LandingPagePsicologiaViva;
