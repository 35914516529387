import * as React from 'react';
import { hasWindow } from '@web/utils/platform';
import { loadableErrorDataSource } from './loadable-error.datasource';
import { cleanCache } from '../clean-cache/clean-cache';
import { Redirect } from '@reach/router';

interface LoadableLoadingWrapperProps {
  children: React.ReactNode;
}

interface LoadableLoadingWrapperState {
  error: unknown
}

/**
 * Use this component to wrap a Loadable loading. It enhances a loading view
 * by handling errors
 * https://loadable-components.com/docs/error-boundaries/
 *
 */
export class LoadableLoadingWrapper extends React.Component<LoadableLoadingWrapperProps, LoadableLoadingWrapperState> {
  private sessionTokenDataSource = loadableErrorDataSource;

  constructor(props: LoadableLoadingWrapperProps) {
    super(props);
    this.state = { error: null };

  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ERROR: loadable-loading-wrapper.component.tsx ~ line 36 ~ LoadableLoadingWrapper ~ componentDidCatch ~ error, errorInfo", error, errorInfo)
  }


  render(): React.ReactNode {
    if (this.state.error) {
      const url = hasWindow() ? window.location.pathname : '-';
      const hasAlreadyReload = this.sessionTokenDataSource.getPageLoadingError().has(url);

      if (!hasAlreadyReload) {
        // Sometimes, due to some PWA update, there are some issues with loading
        // Loadable chunks. The following code cleans the cache when some
        // Loadable error happens and reload the page. If that doesn't work, the
        // user gets redirected to erro 500.

        /** tries to load this loadable again */
        this.sessionTokenDataSource.addPageLoadingError(url);

        cleanCache().then(() => {
          if (hasWindow()) {
            window.location.reload();
          }
        });
        return this.props.children;
      }

      /** send user to error500 if it has already tried to load this Loadable */
      this.sessionTokenDataSource.removePageLoadingError(url);

      const toValue = { pathname: '/404' };
      return <Redirect to={toValue.pathname} />;
    }

    return this.props.children;
  }
}
