/* eslint-disable react/display-name */
import loadable from '@loadable/component';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { LoadableLoadingWrapper } from '@lp-src/utils/loadable/loadable-loading-wrapper.component';
import { Body, H1, Separator } from '@web/atomic';
import { Col, Row } from '@web/atomic/legacy/obj.grid/grid.component';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

const Loading: React.FunctionComponent = (props) => {
  const data = useStaticQuery(graphql`
    {
      girl: file(relativePath: { eq: "girl-with-mask.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 930, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <Row mt center="xs">
        <Col xs={9} sm={7} md={7} lg={6}>
          <RelativeWrapper>
            <GatsbyImage image={data.girl.childImageSharp.gatsbyImageData} alt="garota com máscara" />
          </RelativeWrapper>
        </Col>
      </Row>
      <Row center={'xs'}>
        <Col xs={12} sm={9} md={9} lg={6}>
          <Separator />
          <H1 center>Qual é a pontuação do seu sono?</H1>
          <Separator />
          <Body>
            Descubra o quão bom é o seu sono e veja uma dica de sono para você aplicar já hoje à noite! Fique tranquilo/a, as informações
            são confidenciais e armazenadas de forma segura.{' '}
            <span role="img" aria-label="segurança">
              {' '}
              &#128274; &#128077;{' '}
            </span>
          </Body>
        </Col>
      </Row>
      <Row mt center="xs">
        <Col xs={12} sm={10}>
          <GatsbyButton loading={true} type="button" kind="primary" expanded>
            Começar
          </GatsbyButton>
          <Separator />
        </Col>
      </Row>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

const LoadableMultistepQuiz = loadable(() => import('./multistep-quiz.component'), {
  fallback: <Loading />,
});

export default (props) => (
  <LoadableLoadingWrapper>
    <LoadableMultistepQuiz {...props} />
  </LoadableLoadingWrapper>
);
