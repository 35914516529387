import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { getStorage, StorageType } from '@web/data/storage/storage.provider';

const PAGE_LOADING_ERROR_KEY = 'pageLoadingError';
const PAGE_LOADING_ERROR_SEPARATOR = ' ';

/**
 * This component respostas whether a first attempt to fix an error while loading
 * Loadable chunks has been done for a given url
 * It saves everything in the SessionStorage
 */
class LoadableErrorDataSource {
  private storage = getStorage(StorageType.Session);

  addPageLoadingError(name: string): void {
    const currPageLoadingError: Set<string> = this.getPageLoadingError();
    currPageLoadingError.add(name);
    this.savePageLoadingError(currPageLoadingError);
  }

  removePageLoadingError(name: string): void {
    const pageLoadingError: Set<string> = this.getPageLoadingError();
    pageLoadingError.delete(name);
    this.savePageLoadingError(pageLoadingError);
  }

  cleanPageLoadingError(): void {
    this.storage.put(PAGE_LOADING_ERROR_KEY, '');
  }

  getPageLoadingError(): Set<string> {
    const errorSetString = this.storage.get(PAGE_LOADING_ERROR_KEY);
    let errorSet = new Set<string>();
    if (!isNullOrUndefined(errorSetString)) {
      errorSet = new Set(errorSetString.split(PAGE_LOADING_ERROR_SEPARATOR));
    }
    return errorSet;
  }

  private savePageLoadingError(nextPageLoadingErrorSet: Set<string>) {
    const nextPageLoadingError: string = Array.from(nextPageLoadingErrorSet).join(PAGE_LOADING_ERROR_SEPARATOR);
    this.storage.put(PAGE_LOADING_ERROR_KEY, nextPageLoadingError);
  }
}

export const loadableErrorDataSource = new LoadableErrorDataSource();
