export const cleanCache = (): Promise<boolean[]> => {
  if (typeof caches !== 'undefined') {
    return caches.keys().then((cacheNames) => {
      // Step through each cache name and delete it
      return Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
    });
  }

  return Promise.reject('caches is not supported');
};
